<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="mb-1 mb-md-0"
          >
            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <label>Show</label>
                <v-select
                  v-model="per_page"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="per_pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <b-button
                  class="table-header-btn"
                  variant="primary"
                  :to="{ name: 'license-new', params: { type: 'Broadcasting' } }"
                >
                  Apply for new License
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refLicencesList"
        class="position-relative font-weight-bolder"
        :items="fetchBroadcastingLicenses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Id -->
        <template #cell(license_id)="data">
          <b-link
            :to="{ name: 'licence-view', params: { id: data.item.license_id }}"
            class="font-weight-bold"
          >
            #{{ data.item.license_id }}
          </b-link>
        </template>

        <!-- Column: Uploaded on -->
        <template #cell(valid_until)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moment(data.item.valid_until).format("d-M-YYYY")
            }}</span>
          </div>
        </template>

        <!-- Tariff -->
        <template #cell(tariff)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              shortenTariff(data.item.tariff.name)
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="downloadLicense(data.item)"
            >
              Download
            </b-button>

            <b-button
              :to="{ name: 'licence-view', params: { id: data.item.license_id }}"
              variant="primary"
              size="sm"
              class="ml-1"
            >
              View
            </b-button>
            <b-button
              :to="{ name: 'licence-edit', params: { id: data.item.license_id }}"
              variant="danger"
              size="sm"
              class="ml-1"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalLicences"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import licenceStoreModule from './licenceStoreModule'
import useLicencesList from './useLicencesList'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const LICENSE_APP_STORE_MODULE_NAME = 'licenses'
    // Use toast
    const toast = useToast()

    // Register module
    if (!store.hasModule(LICENSE_APP_STORE_MODULE_NAME)) store.registerModule(LICENSE_APP_STORE_MODULE_NAME, licenceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LICENSE_APP_STORE_MODULE_NAME)) store.unregisterModule(LICENSE_APP_STORE_MODULE_NAME)
    })

    const isAddNewArticleSidebarActive = ref(false)

    const downloadLicense = license => {
      store
        .dispatch('licenses/downloadLicense', license.license_id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${license.license_id}.pdf`,
          )
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error accessing file. Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const shortenTariff = tariff => {
      if (tariff.length > 20) {
        return `${tariff.substr(0, 20)}...`
      }
      return tariff
    }

    const {
      fetchBroadcastingLicenses,
      tableColumns,
      per_page,
      current_page,
      totalLicences,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLicencesList,
      refetchData,
    } = useLicencesList()

    return {
      // Sidebar
      isAddNewArticleSidebarActive,

      fetchBroadcastingLicenses,
      tableColumns,
      per_page,
      current_page,
      totalLicences,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLicencesList,
      refetchData,

      shortenTariff,

      downloadLicense,
    }
  },
}
</script>

<style scoped>
@media only screen and (max-width: 992px) {
  .table-header-btn {
    margin-top: 3px;
    margin-right: 0;
  }
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
            > .col-12 {
            max-width: 100% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
